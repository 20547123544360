var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.title}},[_c('v-select',{attrs:{"options":_vm.options,"label":"value","placeholder":_vm.placeholder,"searchable":_vm.isSearchable,"clearable":true,"reduce":function (item) { return item.key; }},on:{"search":_vm.fetchOptions,"option:selected":_vm.loadDetail},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var key = ref.key;
var value = ref.value;
var additionalInfos = ref.additionalInfos;
return [_c('em',[_c('i',{class:_vm.infoIcon(additionalInfos)}),_vm._v(" "+_vm._s(value))])]}},{key:"selected-option",fn:function(ref){
var key = ref.key;
var value = ref.value;
var additionalInfos = ref.additionalInfos;
return [_c('i',{class:_vm.infoIcon(additionalInfos)}),_vm._v(" "+_vm._s(value)+" ")]}}]),model:{value:(_vm.currentCode),callback:function ($$v) {_vm.currentCode=$$v},expression:"currentCode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }